import './App.css';
import { Routes, Route,useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/Signin';
import Taxonomy from './pages/Taxonomy';
import Page404 from './pages/Page404';
import Vendors from './pages/Vendors';
import Users from './pages/Users';
import ProjectDraft from './pages/ProjectDraft';
import ProductPart from './pages/ProductPart';

function ProfilePage() {
  // Get the userId param from the URL.
  let { id } = useParams();
  // ...
  console.log(id)
}



function App() {
  
  const config = { 
    appName: 'SIMPRO',
    appDesc: 'Sistem Informasi Management Produksi',
    appDomain: 'NetMaster.ID',
    appTitle : "NetMaster.ID - Network Services Management",
    locStorage : 'simpro_cache',
    home : 'https://simpro.last40.com/',
    hostApi : 'https://api.simpro.last40.com/',
    ver : 'ver. 0.24.06'
  }
  const [cookies] = useCookies(['bas_']);

  return (
    <Routes>
      <Route path="*" element={<Page404 />} />
      <Route path="/" element={<Dashboard config={config} cookies={cookies} />} />
      
      <Route path="/signin" element={<SignIn config={config} />} />
      <Route path="/signout" element={<SignIn config={config} action="signout" />} />

      <Route path="/dashboard" element={<Dashboard config={config} />} />
      <Route path='/project-draft'>
          <Route path=":id" element={<ProjectDraft config={config} />} />
      </Route>
      <Route path='/product-part'>
          <Route path=":id" element={<ProductPart config={config} />} />
      </Route>
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/vendors" element={<Vendors config={config} />} />

      <Route path="/users" element={<Users config={config} />} />
      <Route path="/taxo" element={<Taxonomy config={config} />} /> 

    </Routes>
  );
}

export default App;
